import {
  SET_FORM_VALUES,
  SET_CARD_ERROR_MESSAGE,
  SET_IS_LOADING,
  SET_STRIPE_COMPLETE,
  SET_LOCATION_DATA,
} from './actions';

const reducer = (
  state = {
    trackedFormValues: {},
    errorMessage: null,
    isLoading: false,
    isStripeComplete: false,
    locationData: null,
  },
  action,
) => {
  switch (action.type) {
    case SET_FORM_VALUES:
      return { ...state, trackedFormValues: action.payload };
    case SET_CARD_ERROR_MESSAGE:
      return { ...state, errorMessage: action.payload };
    case SET_IS_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_STRIPE_COMPLETE:
      return { ...state, isStripeComplete: action.payload };
    case SET_LOCATION_DATA:
      return { ...state, locationData: action.payload };
    default:
      return state;
  }
};

export default reducer;

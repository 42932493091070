/* eslint-disable no-underscore-dangle */
/* eslint-disable prefer-object-spread/prefer-object-spread */
import { createStore as reduxCreateStore, applyMiddleware, combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import logger from 'redux-logger';
import reducer from './reducer';

const combinedReducer = combineReducers({
  state: reducer,
  form: formReducer,
});

const createStore = () => reduxCreateStore(combinedReducer, applyMiddleware(logger));
export default createStore;

export const SET_FORM_VALUES = 'SET_FORM_VALUES';
export const SET_CARD_ERROR_MESSAGE = 'SET_CARD_ERROR_MESSAGE';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_STRIPE_COMPLETE = 'SET_STRIPE_COMPLETE';
export const SET_LOCATION_DATA = 'SET_LOCATION_DATA';

export const setFormValues = formValues => ({ type: SET_FORM_VALUES, payload: formValues });
export const clearFormValues = () => ({ type: SET_FORM_VALUES, payload: {} });

export const setErrorMessage = message => ({ type: SET_CARD_ERROR_MESSAGE, payload: message });
export const clearErrorMessage = () => ({ type: SET_CARD_ERROR_MESSAGE, payload: null });

export const setIsLoadingToTrue = () => ({ type: SET_IS_LOADING, payload: true });
export const setIsLoadingToFalse = () => ({ type: SET_IS_LOADING, payload: false });

export const setIsStripeComplete = isComplete => ({
  type: SET_STRIPE_COMPLETE,
  payload: isComplete,
});

export const setLocationData = data => ({ type: SET_LOCATION_DATA, payload: data });

module.exports = [{
      plugin: require('/tmp/build_d890fc16/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.hiclark.com"},
    },{
      plugin: require('/tmp/build_d890fc16/node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"JwYquflxYTsWkLCOzYokHmZnHDjYScT6","devKey":"Or1lSYVw6NGz5f43KiuOPQhSxLbKGYMD","trackPage":true},
    },{
      plugin: require('/tmp/build_d890fc16/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
